<template>
  <main class="edit-user">
    <b-col class="col-12">
      <div class="page-title-box">
        <b-breadcrumb :items="breadcrumb" class="m-0" />

        <div class="d-flex align-items-center">
          <i class="fas fa-angle-left" />
          <router-link to="/customers/add">
            <span style="margin-left: 5px">Voltar</span>
          </router-link>
        </div>
      </div>
    </b-col>

    <b-row>
      <b-col class="col-12">
        <b-card>
          <h4 class="header2">Dados pessoais</h4>
          <div class="row">
            <AdminFormGroup label="Nome completo" cols="6">
              <b-form-input
                placeholder="Nome completo"
                name="customer-profile-name-input"
                v-model="funcionario.pessoa.pessoa_nome"
              />
            </AdminFormGroup>
            <AdminFormGroup label="Profissão" cols="6">
              <b-form-input
                placeholder="Profissão"
                name="customer-profile-name-input"
                v-model="funcionario.pessoa.pessoa_profissao"
              />
            </AdminFormGroup>
          </div>
          <div class="row">
            <AdminFormGroup label="CPF" cols="4">
              <b-form-input
                placeholder="CPF"
                name="customer-profile-name-input"
                v-mask="'###.###.###-##'"
                v-model="funcionario.pessoa.pessoa_cpf"
                :masked="false"
              />
            </AdminFormGroup>
            <AdminFormGroup label="Email" cols="4">
              <b-form-input
                placeholder="Email"
                name="customer-profile-name-input"
                v-model="funcionario.pessoa.pessoa_email"
              />
            </AdminFormGroup>
            <AdminFormGroup label="Estado Civil" cols="4">
              <b-form-select
                class="browser-default"
                v-model="funcionario.pessoa.pessoa_estado_civil"
                id="pessoa_estado_civil"
              >
                <option value="" selected disabled>Selecione ...</option>
                <option value="C">Casado</option>
                <option value="S">Solteiro</option>
                <option value="D">Divorciado</option>
                <option value="V">Viúvo</option>
                <option value="O">Outros</option>
                <option value="SJ">Separado Judicialmente</option>
                <option value="UE">União Estável</option>
              </b-form-select>
            </AdminFormGroup>
          </div>
          <div class="row">
            <AdminFormGroup label="Sexo" cols="3">
              <b-form-select
                class="browser-default"
                v-model="funcionario.pessoa.pessoa_sexo"
                id="pessoa_estado_civil"
              >
                <option value="" selected disabled>Selecione ...</option>
                <option value="M">Masculino</option>
                <option value="F">Feminino</option>
              </b-form-select>
            </AdminFormGroup>
            <AdminFormGroup label="Telefone" cols="3">
              <b-form-input
                placeholder="Telefone"
                v-mask="'(##) ####-####'"
                name="customer-profile-name-input"
                v-model="funcionario.pessoa.pessoa_telefone"
              />
            </AdminFormGroup>
            <AdminFormGroup label="Celular" cols="3">
              <b-form-input
                placeholder="Celular"
                v-mask="'(##) # ####-####'"
                name="customer-profile-name-input"
                v-model="funcionario.pessoa.pessoa_celular"
              />
            </AdminFormGroup>
            <AdminFormGroup label="Data de nascimento" cols="3">
              <b-form-input
                type="date"
                placeholder="Data de nascimento"
                v-model="funcionario.pessoa.pessoa_data_nascimento"
              />
            </AdminFormGroup>
          </div>
          <div class="row" v-if="simuladorStatus == 0">
            <AdminFormGroup label="Possui filhos" cols="6">
              <b-form-select
                class="browser-default"
                v-model="flagFilhos"
                id="pessoa_estado_civil"
              >
                <option value="" selected disabled>Selecione ...</option>
                <option :value="true">Sim</option>
                <option :value="false">Não</option>
              </b-form-select>
            </AdminFormGroup>
            <AdminFormGroup label="Quantos" cols="6" v-if="flagFilhos">
              <b-form-input
                placeholder="Quantos"
                name="customer-profile-name-input"
                v-model="funcionario.pessoa.pessoa_filhos"
              />
            </AdminFormGroup>
          </div>
          <div class="row mt-2">
            <AdminFormGroup label="Observações">
              <b-form-textarea
                id="expense-observation-textarea"
                rows="5"
                v-model="funcionario.pessoa.pessoa_observacao"
                placeholder="Observação..."
              />
            </AdminFormGroup>

            <AdminFormGroup label="CEP" cols="4">
              <b-form-input
                placeholder="CEP"
                v-model="funcionario.pessoa.endereco.endereco_cep"
                v-mask="'#####-###'"
                @input="searchAddress(funcionario.pessoa.endereco.endereco_cep)"
              />
            </AdminFormGroup>

            <AdminFormGroup label="Estado" cols="4">
              <b-form-input
                placeholder="Estado"
                v-model="funcionario.pessoa.endereco.endereco_estado"
              />
            </AdminFormGroup>

            <AdminFormGroup label="Cidade" cols="4">
              <b-form-input
                placeholder="Cidade"
                v-model="funcionario.pessoa.endereco.endereco_cidade"
              />
            </AdminFormGroup>

            <AdminFormGroup label="Endereço" cols="4">
              <b-form-input
                placeholder="Rua, Av."
                v-model="funcionario.pessoa.endereco.endereco_logradouro"
              />
            </AdminFormGroup>

            <AdminFormGroup label="Número" cols="2">
              <b-form-input
                placeholder="Número"
                v-model="funcionario.pessoa.endereco.endereco_numero"
              />
            </AdminFormGroup>

            <AdminFormGroup label="Complemento" cols="2">
              <b-form-input
                placeholder="Casa, Ap."
                v-model="funcionario.pessoa.endereco.endereco_complemento"
              />
            </AdminFormGroup>

            <AdminFormGroup label="Bairro" cols="4">
              <b-form-input
                placeholder="Bairro"
                v-model="funcionario.pessoa.endereco.endereco_bairro"
              />
            </AdminFormGroup>
          </div>
          <div class="formulario-funcionario">
            <h4 class="header2">Dados Usuário</h4>
            <div class="row">
              <AdminFormGroup label="Empresa" cols="6">
                <b-form-select
                  class="browser-default"
                  id="pessoa_empresa"
                  v-model="funcionario.empresa_id"
                  :options="companies"
                >
                </b-form-select>
              </AdminFormGroup>
              <AdminFormGroup label="Cargo" cols="6">
                <b-form-select
                  class="browser-default"
                  id="pessoa_estado_civil"
                  v-model="funcionario.funcionario_cargo_id"
                  :options="cargoOptions"
                >
                </b-form-select>
              </AdminFormGroup>
            </div>
            <h4 class="header2">Acesso</h4>
            <div class="row">
              <AdminFormGroup label="Usuário" cols="6">
                <b-form-input
                  placeholder="Usuário"
                  name="customer-profile-name-input"
                  v-model="funcionario.pessoa.usuario.usuario_username"
                />
              </AdminFormGroup>
              <AdminFormGroup label="Senha" cols="6">
                <b-form-input
                  placeholder="Senha"
                  type="password"
                  name="customer-profile-name-input"
                  v-model="funcionario.pessoa.usuario.senha"
                />
              </AdminFormGroup>

              <AdminFormGroup label="Funcionários relacionados:" cols="6">
                <multi-select
                  :selected-options="funcionario.funcionario_relacionamentos"
                  :options="opcoesFuncionarios"
                  @change="findFuncionarios"
                  :multiple="true"
                  @select="onSelect"
                >
                </multi-select>
              </AdminFormGroup>
              <b-row class="mb-3 mt-3 px-3 ml-auto">
                <b-col sm="12">
                  <b-button variant="info" type="submit" @click="saveUser()">
                    SALVAR USUÁRIO
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-card>

        <b-card v-if="editMode">
          <h4>Editar Especialidades</h4>
          <b-row>
            <b-col
              class="col-12 d-flex mt-3"
              style="justify-content: space-evenly"
            >
              <button
                class="btn"
                :style="`margin: 4px 12px; border: 2px solid ${
                  produtoDiagnostico.cor
                }; ${
                  possuiProdutoDiagnostico(produtoDiagnostico)
                    ? `background-color: ${produtoDiagnostico.cor}; color: #fff;`
                    : `background-color: #fff; color: ${produtoDiagnostico.cor};`
                }`"
                v-for="produtoDiagnostico in produtoDiagnosticos"
                :key="produtoDiagnostico.produto_diagnostico_id"
                @click="toggleEspecialidadeProduto(produtoDiagnostico)"
              >
                {{ produtoDiagnostico.nome }}
              </button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </main>
</template>

<script>
import AdminFormGroup from './AdminFormGroup'
import {
  registerUser,
  getFuncionarioOpcoes,
  updateUser,
} from '@/services/requests/users'
import { getAllCompanies } from '@/services/requests/company'
import { getSpecialties } from '@/services/requests/specialties'
import { cloneObject } from '@/helpers'
import { getAddress } from '@/services/requests/cep'
import { MultiSelect } from 'vue-search-select'
import 'vue-search-select/dist/VueSearchSelect.css'
import api from '@/utils/api'

import Vue from 'vue'
import VueMask from 'v-mask'
Vue.use(VueMask)

export default {
  name: 'UserInfo',
  components: { AdminFormGroup, MultiSelect },
  params: {
    id: Number,
  },
  data() {
    return {
      breadcrumb: [
        {
          text: 'Lista de usuários',
          href: '/',
        },
        {
          text: 'Lista de usuários',
          active: true,
        },
      ],
      opcoesFuncionarios: [{ value: 1, text: 'Selecione' }],
      cargoOptions: [
        {
          value: null,
          text: 'Selecione um cargo',
          disabled: true,
          select: true,
        },
        { value: 1, text: 'Admin' },
        { value: 2, text: 'Gerente' },
        { value: 3, text: 'Corretor' },
        { value: 4, text: 'Especialista Master' },
        { value: 5, text: 'Líder' },
      ],
      simuladorStatus: 0,
      flagFilhos: false,
      funcionario: {
        funcionario_cargo_id: 1,
        funcionario_relacionamentos: [],
        empresa_id: 0,
        empresas_ids: [],
        pessoa: {
          pessoa_nome: '',
          pessoa_cpf: '',
          pessoa_email: '',
          pessoa_sexo: '',
          pessoa_telefone: '',
          pessoa_celular: '',
          pessoa_data_nascimento: '',
          pessoa_profissao: '',
          pessoa_estado_civil: '',
          pessoa_filhos: 0,
          pessoa_observacao: '',

          endereco: {
            endereco_cep: '',
            endereco_estado: '',
            endereco_cidade: '',
            endereco_bairro: '',
            endereco_logradouro: '',
            endereco_numero: '',
            endereco_complemento: '',
          },
          usuario: { usuario_username: '', senha: '' },
        },
      },
      companies: [],
      produtoDiagnosticos: [],
    }
  },
  computed: {
    editMode() {
      if (this.$route.params.id) {
        return true
      }
      return false
    },
    userId() {
      return this.$route.params.id
    },
  },
  created: async function () {
    this.getEmpresas()

    this.findFuncionarios()

    this.getAllSpecialties()

    if (this.editMode) {
      api
        .get(`funcionario/${this.userId}`)
        .then((res) => {
          const data = res.data.result.data
          if (!data.pessoa.endereco) {
            data.pessoa.endereco = {
              endereco_cep: '',
              endereco_estado: '',
              endereco_cidade: '',
              endereco_bairro: '',
              endereco_logradouro: '',
              endereco_numero: '',
              endereco_complemento: '',
            }
          }
          if (data.funcionario_relacionamentos) {
            data.funcionario_relacionamentos =
              data.funcionario_relacionamentos.map((item) => {
                return {
                  value: item.funcionario_relacionado_id,
                  text: item.funcionario_relacionado.pessoa.pessoa_nome,
                }
              })
          }
          this.funcionario = data
        })
        .catch(() => {
          this.$router.push('/')
        })
    } else {
      return
    }
  },
  methods: {
    onSelect(items) {
      this.funcionario.funcionario_relacionamentos = items
    },
    async getAllSpecialties() {
      await getSpecialties().then(
        ({
          data: {
            result: { data },
          },
        }) => {
          this.produtoDiagnosticos = data
        }
      )
    },
    checkValue(value) {
      if (value !== '' && value !== '___.___.___-__') {
        return 'active'
      }
      return ''
    },
    async searchAddress(cep) {
      if (!/\d{5}-\d{3}/.test(cep)) return

      const response = await getAddress(cep)

      this.funcionario.pessoa.endereco.endereco_logradouro = response.logradouro
      this.funcionario.pessoa.endereco.endereco_bairro = response.bairro
      this.funcionario.pessoa.endereco.endereco_estado = response.uf
      this.funcionario.pessoa.endereco.endereco_cidade = response.localidade
    },
    saveUser() {
      const confirm = window.confirm('Deseja realmente adicionar esse usuário?')
      if (!confirm) return

      const data = cloneObject(this.funcionario)

      let postData = {
        funcionario_cargo_id: data.funcionario_cargo_id,
        funcionario_relacionamentos: data.funcionario_relacionamentos
          ? data.funcionario_relacionamentos.map((person) => {
              return person.value
            })
          : [],
        empresa_id: data.empresa_id,
        empresas_ids: data.empresa_id,
        pessoa_nome: data.pessoa.pessoa_nome,
        pessoa_cpf: data.pessoa.pessoa_cpf
          ? data.pessoa.pessoa_cpf.replace(/\W/g, '')
          : '',
        pessoa_email: data.pessoa.pessoa_email,
        pessoa_sexo: data.pessoa.pessoa_sexo,
        pessoa_telefone: data.pessoa.pessoa_telefone
          ? data.pessoa.pessoa_telefone.replace(/\W/g, '')
          : '',
        pessoa_celular: data.pessoa.pessoa_celular
          ? data.pessoa.pessoa_celular.replace(/\W/g, '')
          : '',
        pessoa_data_nascimento: data.pessoa.pessoa_data_nascimento
          ? data.pessoa.pessoa_data_nascimento.replace(/\//g, '-')
          : '',
        pessoa_profissao: data.pessoa.pessoa_profissao,
        pessoa_estado_civil: data.pessoa.pessoa_estado_civil,
        pessoa_filhos: data.pessoa.pessoa_filhos,
        pessoa_observacao: data.pessoa.pessoa_observacao,

        endereco_cep: data.pessoa.endereco.endereco_cep,
        endereco_estado: data.pessoa.endereco.endereco_estado,
        endereco_cidade: data.pessoa.endereco.endereco_cidade,
        endereco_bairro: data.pessoa.endereco.endereco_bairro,
        endereco_logradouro: data.pessoa.endereco.endereco_logradouro,
        endereco_numero: data.pessoa.endereco.endereco_numero,
        endereco_complemento: data.pessoa.endereco.endereco_complemento,

        usuario: data.pessoa.usuario.usuario_username,
        senha: data.pessoa.usuario.senha,
      }

      if (this.editMode) {
        updateUser(postData, this.userId)
          .then(
            () =>
              this.$bvToast.toast('Usuário atualizado.', {
                title: `Sucesso!`,
                toaster: 'b-toaster-bottom-right',
                variant: 'success',
                solid: true,
                autoHideDelay: 6000,
              }),
            this.$router.push('/')
          )
          .catch(() => {
            this.$bvToast.toast('Falha ao atualizar usuário', {
              title: `ATENÇÃO!`,
              toaster: 'b-toaster-bottom-right',
              variant: 'danger',
              solid: true,
              autoHideDelay: 6000,
            })
          })
      } else {
        registerUser(postData)
          .then(
            () =>
              this.$bvToast.toast('Usuário registrado.', {
                title: `Sucesso!`,
                toaster: 'b-toaster-bottom-right',
                variant: 'success',
                solid: true,
                autoHideDelay: 6000,
              }),
            this.$router.push('/')
          )
          .catch(() => {
            this.$bvToast.toast('Falha ao registrar usuário', {
              title: `ATENÇÃO!`,
              toaster: 'b-toaster-bottom-right',
              variant: 'danger',
              solid: true,
              autoHideDelay: 6000,
            })
          })
      }
    },
    async getEmpresas() {
      await getAllCompanies().then((response) => {
        const data = response.data.result.data.data.map((item) => {
          return { value: item.empresa_id, text: item.empresa_nome_fantasia }
        })

        this.companies = data
      })
    },
    async findFuncionarios() {
      await getFuncionarioOpcoes('funcionario/opcoes').then((response) => {
        let data = response.data.result.data

        this.opcoesFuncionarios = data.map((funcionario) => ({
          value: funcionario.funcionario_id,
          text: funcionario.pessoa.pessoa_nome,
        }))
      })
    },
    possuiProdutoDiagnostico(produtoDiagnostico) {
      if (!this.funcionario || !this.funcionario.funcionario_especialidades)
        return null

      return this.funcionario.funcionario_especialidades.some(
        (especialidade) =>
          especialidade.produto_diagnostico_id ===
          produtoDiagnostico.produto_diagnostico_id
      )
    },
    toggleEspecialidadeProduto(produtoDiagnostico) {
      if (this.possuiProdutoDiagnostico(produtoDiagnostico)) {
        const especialidade = this.funcionario.funcionario_especialidades.find(
          (especialidade) =>
            especialidade.produto_diagnostico_id ===
            produtoDiagnostico.produto_diagnostico_id
        )

        api
          .delete(
            `funcionario/especialidade/${especialidade.funcionario_especialidade_id}`
          )
          .then(() => this.atualizarEspecialidades())
      } else {
        const data = {
          produto_diagnostico_id: produtoDiagnostico.produto_diagnostico_id,
        }

        api
          .post(
            `funcionario/${this.funcionario.funcionario_id}/especialidade`,
            data
          )
          .then(() => this.atualizarEspecialidades())
      }
    },
    atualizarEspecialidades() {
      api.get(`funcionario/${this.userId}`).then(
        ({
          data: {
            result: { data },
          },
        }) => {
          this.funcionario.funcionario_especialidades =
            data.funcionario_especialidades
        }
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.page-title-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.edit-user {
  padding: 1rem;

  h4 {
    font-size: 1rem;
    font-weight: 600;
  }

  label {
    font-size: 0.8rem;
  }
}

.btn {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}
</style>
