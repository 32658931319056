import Vue from 'vue'
import { format as formatDate, isSameDay, isSameWeek, subDays } from 'date-fns'
import { ptBR } from 'date-fns/locale'

Vue.filter('formatDate', (value, format = 'dd,MM,yy') => {
  if (!value) return ''
  return formatDate(new Date(value), format)
})

Vue.filter('formatDateWithSlash', (value, format = 'dd/MM/yyyy') => {
  if (!value) return ''
  return formatDate(new Date(value), format)
})

Vue.filter('formatDateTime', (value) => {
  if (!value) return ''
  return formatDate(new Date(value), 'dd/MM/yyyy HH:mm/h')
})

Vue.filter('formatTime', (value) => {
  if (!value) return ''
  return formatDate(new Date(value), 'HH:mm')
})

Vue.filter('formatDateWithCompleteMonth', (value) => {
  if (!value) return ''
  return formatDate(new Date(value), 'dd/MMMM/yyyy')
})

Vue.filter('formatDateToText', (value) => {
  if (!value) return ''

  const date = new Date(value)
  const today = new Date()

  if (isSameDay(today, date)) {
    return 'Hoje'
  }

  const yesterday = subDays(today, 1)
  if (isSameDay(yesterday, date)) {
    return 'Ontem'
  }

  if (isSameWeek(date, today)) {
    return formatDate(date, 'EEEE', { locale: ptBR })
  }

  return formatDate(date, 'dd/MM/yyyy')
})

Vue.filter('uppercase', (value) => {
  if (!value) return ''
  return String(value).toUpperCase()
})

Vue.filter('cnpj', (value) => {
  const cnpj = String(value).replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    '$1.$2.$3/$4-$5'
  )

  return cnpj
})

Vue.filter('cpf', (value) => {
  if (!value) return ''

  const cnpj = String(value).replace(
    /^(\d{3})(\d{3})(\d{3})(\d{2})/,
    '$1.$2.$3-$4'
  )
  return cnpj
})

Vue.filter('genderName', (value) => {
  if (value === 'M') return 'Masculino'
  if (value === 'F') return 'Feminino'
  if (value === 'O') return 'Outro'

  return ''
})

Vue.filter('maritalStatusName', (value) => {
  if (value === 'C') return 'Casado'
  if (value === 'S') return 'Solteiro'
  if (value === 'D') return 'Divorciado'
  if (value === 'V') return 'Viúvo'
  if (value === 'O') return 'Outros'
  if (value === 'SJ') return 'Separado Judicialmente'
  if (value === 'UE') return 'União Estável'

  return ''
})

Vue.filter('statusSmoke', (value) => {
  if (value === 1) return 'Fumante'
  if (value === 2) return 'Não Fumante'
  if (value === 3) return 'Ex Fumante'

  return ''
})

Vue.filter('periodicidadePagamento', (value) => {
  if (value === 'monthly') return 'Mensal'
  if (value === 'yearly') return 'Anual'

  return ''
})

Vue.filter('formaPagamento', (value) => {
  if (value === 'card') return 'Cartão'
  if (value === 'invoice') return 'Boleto'

  return ''
})

Vue.filter('periodoCobertura', (value) => {
  if (!value) return ''
  if (Number(value) === 999) return 'Vitalício'

  return `${value} anos`
})

function valueToDecimal(value, decimal = 2) {
  if (!value) return '0,00'

  let val = (value / 1).toFixed(decimal).replace('.', ',')
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

Vue.filter('decimal', valueToDecimal)

Vue.filter('boolean', (value) => {
  if (value) return 'Sim'
  return 'Não'
})

Vue.filter('decimalWithoutCents', (value) => {
  return valueToDecimal(value, 0)
})

Vue.filter('cellphone', (value) => {
  if (!value) return ''

  return value.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
})

Vue.filter('phone', (value) => {
  return value.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3')
})

Vue.filter('address', (address) => {
  if (!address || typeof address !== 'object') {
    return ''
  }

  const {
    endereco_logradouro: logradouro,
    endereco_numero: numero,
    endereco_bairro: bairro,
    endereco_cidade: cidade,
    endereco_estado: estado,
  } = address

  return `${logradouro ? `${logradouro}, ` : ''}${
    numero ? `${numero} - ` : ''
  }${bairro ? `${bairro} - ` : ''}${cidade ? `${cidade} - ` : ''}${
    estado ? `${estado}` : ''
  }`
})
