import * as loggedAsTypes from '@/constants/loggedAsTypes'
import { verifyRouteRolesUser } from '@/router/middlewares'

const PrepareQuote = () => import('../pages/PrepareQuote.vue')
const AwaitingConsultant = () => import('../pages/AwaitingConsultant.vue')
const ExecutionDocumentsUpload = () =>
  import('../pages/ExecutionDocumentsUpload.vue')
const ImplementationSolutions = () =>
  import('../pages/ImplementationSolutions.vue')

export default [
  {
    path: '/activity-panel/proposal-quotation/:diagnosisId(\\d+)',
    name: 'ProposalAndQuotation',
    component: PrepareQuote,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.SPECIALIST]),
  },
  {
    path: '/activity-panel/awaiting-consultant/:diagnosisId(\\d+)',
    name: 'AwaitingConsultant',
    component: AwaitingConsultant,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.SPECIALIST]),
  },
  {
    path: '/activity-panel/implementation-solutions/:diagnosisId(\\d+)',
    name: 'ImplementationSolutions',
    component: ImplementationSolutions,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.SPECIALIST]),
  },
  {
    path: '/activity-panel/execution-documents-upload/:diagnosisId(\\d+)',
    name: 'ExecutionDocumentsUpload',
    component: ExecutionDocumentsUpload,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.SPECIALIST]),
  },
]
