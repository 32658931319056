import { getInsuranceCompany } from '@/services/requests/company'

const state = {
  insurers: [],
  shouldUpdateDiagnosis: false,
}

const getters = {
  insurersOptions: (state) => {
    return [
      { value: '', text: 'Selecione' },
      ...state.insurers.map((insurer) => ({ value: insurer, text: insurer })),
      { value: 'Outra', text: 'Outra' },
    ]
  },
  shouldUpdateDiagnosis: (state) => state.shouldUpdateDiagnosis,
}

export const actions = {
  loadInsurers({ state }) {
    getInsuranceCompany()
      .then((res) => {
        state.insurers = res.data.result.data
      })
      .catch(() => {
        this.$store.state.modal.isOpenModalErrorRequest = true
      })
  },
}

export const mutations = {
  setShouldUpdateDiagnosis(state, shouldUpdate) {
    state.shouldUpdateDiagnosis = shouldUpdate
  },
}

export default {
  name: 'diagnosis',
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
