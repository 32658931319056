<template>
  <highcharts :options="dashboardChart" />
</template>

<script lang="js">
import { Chart } from 'highcharts-vue'

export default ({
  name: 'DashboardGraphic',
  components: {
    highcharts: Chart,
  },
  props: {
    series: Array
  },
  computed: {
    dashboardChart() {
      const dashboardChart = {
        chart: {
          type: 'line',
        },
        title: {
          text: '',
        },
        legend: {
          align: 'center',
          verticalAlign: 'top',
        },
        subtitle: {
          enabled: false,
        },
        xAxis: {
          lineWidth: 0,
          categories: [
            'janeiro',
            'fevereiro',
            'março',
            'abril',
            'maio',
            'junho',
            'julho',
            'agosto',
            'setembro',
            'outubro',
            'novembro',
            'dezembro',
          ],
        },
        yAxis: {
          title: {
            enabled: false,
          },
          visible: false,
        },
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true,
            },
            enableMouseTracking: false,
          },
          series: {
            marker: {
              enabled: false,
            },
          },
        },
        credits: {
          enabled: false,
        },
        series: this.series
      }

      return dashboardChart
    },
  },
})
</script>
