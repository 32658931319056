import { requestDiagnosis } from '@/services/requests/diagnosis'

const state = {
  isOpenModalViewComment: false,
  commentModalViewComment: '',
  isOpenModalAddComment: false,
  itemModalAddComment: null,
  commentModalAddComment: null,
  isOpenModalEditComment: false,
  isOpenModalExamResult: false,
  isOpenModalSuccess: false,
  itemModalEditComment: null,
  isOpenModalInfoClientCard: false,
  dataInfoClientCard: null,
  previousItemIdClientCard: null,
  nextItemIdClientCard: null,
  isOpenModalErrorRequest: false,
  isOpenModalAlert: false,
  errorMessageModal: '',
  isClientActivitiesType: false,
  isOpenClientActivitiesModal: false,
  clientActivitiesModalId: null,
  isLoadingCustomerData: false,
}

export const actions = {
  openModalInfoClientCard({ state }, diagnosisId) {
    requestDiagnosis(diagnosisId)
      .then((res) => {
        state.dataInfoClientCard = res.data.result.data
        state.isOpenModalInfoClientCard = true
      })
      .catch(() => {
        this.$store.state.modal.isOpenModalErrorRequest = true
      })
  },
}

export const mutations = {
  changeIsLoadingCustomerData(state, newValue) {
    state.isLoadingCustomerData = newValue
  },
}

export default {
  name: 'modal',
  namespaced: true,
  state,
  actions,
  mutations,
}
