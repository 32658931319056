function handleInput({ input }) {
  input.setAttribute('data-changed', true)
}

function handleBlur({ input, binding }, event) {
  if (!input.getAttribute('data-changed')) {
    return
  }

  input.removeAttribute('data-changed')

  binding.value(event)
}

function getInput(element) {
  return ['TEXTAREA', 'INPUT'].includes(element.tagName)
    ? element
    : element.querySelector('input,textarea')
}

const inputsData = {}

const onBlurUpdate = {
  bind(element, binding) {
    const input = getInput(element)

    const inputData = {
      id: crypto.randomUUID(),
      listeners: {
        input: handleInput.bind(null, { input, binding }),
        blur: handleBlur.bind(null, { input, binding }),
      },
    }

    input.setAttribute('data-dirty-blur-id', inputData.id)

    input.addEventListener('input', inputData.listeners.input)
    input.addEventListener('blur', inputData.listeners.blur)

    inputsData[inputData.id] = inputData
  },
  update(element, binding) {
    if (binding.value === binding.oldValue) {
      return
    }

    const input = getInput(element)

    const inputData = inputsData[input.getAttribute('data-dirty-blur-id')]

    input.removeEventListener('input', inputData.listeners.input)
    input.removeEventListener('blur', inputData.listeners.blur)

    inputData.listeners = {
      input: handleInput.bind(null, { input, binding }),
      blur: handleBlur.bind(null, { input, binding }),
    }

    input.addEventListener('input', inputData.listeners.input)
    input.addEventListener('blur', inputData.listeners.blur)
  },
}

export default onBlurUpdate
