import { alreadyLoggedGuard } from './auth.middleware'

const LoginPageBroker = () =>
  import(/* webpackChunkName: "LoginPageBroker" */ './LoginPageBroker.vue')

// const LoginPageSpecialist = () =>
//   import(
//     /* webpackChunkName: "LoginPageSpecialist" */ './LoginPageSpecialist.vue'
//   );

const LogoutPage = () =>
  import(/* webpackChunkName: "LogoutPage" */ './LogoutPage.vue')

const ForgotPasswordPage = () =>
  import(
    /* webpackChunkName: "ForgotPasswordPage" */ './ForgotPasswordPage.vue'
  )

const ResetPasswordPage = () =>
  import(/* webpackChunkName: "ResetPasswordPage" */ './ResetPasswordPage.vue')

export default [
  {
    path: '/auth/login',
    name: 'login',
    component: LoginPageBroker,
    beforeEnter: alreadyLoggedGuard,
  },
  // {
  //   path: '/auth/login-specialist',
  //   name: 'loginSpecialist',
  //   component: LoginPageSpecialist,
  //   beforeEnter: alreadyLoggedGuard
  // },
  {
    path: '/auth/logout',
    name: 'logout',
    component: LogoutPage,
  },
  {
    path: '/auth/forgot-password',
    name: 'forgotPassword',
    component: ForgotPasswordPage,
  },
  {
    path: '/auth/reset-password/:token',
    name: 'resetPassword',
    component: ResetPasswordPage,
  },
]
