<template>
  <main>
    <b-col class="col-12">
      <div class="page-title-box">
        <b-breadcrumb :items="breadcrumb" class="m-0" />

        <div class="d-flex align-items-center">
          <i class="fas fa-angle-left" />
          <router-link to="/customers/add">
            <span style="margin-left: 5px">Voltar</span>
          </router-link>
        </div>
      </div>
    </b-col>

    <b-row>
      <b-col class="col-12">
        <b-card>
          <div
            class="d-flex align-center"
            style="justify-content: space-between"
          >
            <TitleAndDescriptionScreen
              title="Lista de usuários "
              description="Confira todos os usuários."
            />

            <b-button-group style="border: none; max-height: 2rem">
              <router-link :to="{ name: 'UsersCadastro' }">
                <b-button variant="primary"> Adicionar usuário </b-button>
              </router-link>
            </b-button-group>
          </div>
          <hr />

          <b-row v-if="!loading" style="margin-top: 2rem" class="overflow-auto">
            <b-table
              hover
              show-empty
              head-variant="light"
              id="users-table"
              empty-text="Não há usuários para mostrar"
              :items="users"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="selectedDateOrder"
            >
              <template #cell(name)="data">
                <span style="color: #495057">
                  {{ data.item.pessoa.pessoa_nome }}
                </span>
              </template>

              <template #cell(office)="data">
                <span style="color: #495057" v-if="data.item.funcionario_cargo">
                  {{ data.item.funcionario_cargo.funcionario_cargo_nome }}
                </span>
              </template>

              <template #cell(email)="data">
                <span style="color: #495057">
                  {{ data.item.pessoa_email }}
                </span>
              </template>

              <template #cell(creation_date)="data">
                <span v-if="data.item.data_criacao">
                  {{ data.item.data_criacao | formatDateWithSlash }}
                </span>
              </template>

              <template #cell(actions)="data">
                <div
                  class="d-flex align-items-center justify-content-between"
                  style="margin-top: 5px"
                >
                  <router-link
                    :to="{
                      name: 'UsersEditar',
                      params: { id: data.item.funcionario_id },
                    }"
                  >
                    <EditButton
                      @click="() => handleClickEdit(data.item.funcionario_id)"
                      :active="true"
                      class="mr-1"
                    />
                  </router-link>
                  <TrashButton
                    :active="true"
                    :click="() => handleDelete(data.item)"
                  />
                </div>
              </template>
            </b-table>

            <!-- <div class="footer-table">
              <div class="box-description-quantity-table">
                <span>{{ pagination.total }} registros</span>
                <span>
                  Mostrando 1 a 10 de
                  <strong>{{ pagination.lastPage }} páginas</strong>
                </span>
              </div>

              <div style="margin: 0 auto">
                <b-pagination
                  v-model="pagination.currentPage"
                  :total-rows="pagination.total"
                  pills
                  :per-page="pagination.perPage"
                  aria-controls="users-table"
                  @change="getUsers"
                />
              </div>
            </div> -->
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </main>
</template>

<script>
import TrashButton from '@/components/Buttons/TrashButton'
import EditButton from '@/components/Buttons/EditButton.vue'
import TitleAndDescriptionScreen from '@/components/common/TitleAndDescriptionScreen'
import { getUsers, deleteUser } from '@/services/requests/users'
export default {
  name: 'UsersPage',
  components: {
    TrashButton,
    TitleAndDescriptionScreen,
    EditButton,
  },
  data() {
    return {
      loading: false,
      breadcrumb: [
        {
          text: 'Lista de usuários',
          href: '/',
        },
        {
          text: 'Lista de usuários',
          active: true,
        },
      ],
      fields: [
        {
          key: 'name',
          label: 'Nome',
        },
        {
          key: 'office',
          label: 'Cargo',
        },
        {
          key: 'email',
          label: 'Email',
        },
        {
          key: 'creation_date',
          label: 'Data de criação',
        },
        {
          key: 'actions',
          label: '',
        },
      ],
      users: [],
      sortBy: 'creation_date',

      optionsSelected: null,
      productSelected: null,
      salesPanelStatusSelected: null,
      selectedDateOrder: 'desc',
      searchText: '',
    }
  },
  computed: {
    sortDesc: {
      get() {
        return this.selectedDateOrder === 'desc'
      },
      set(value) {
        this.selectedDateOrder = value ? 'desc' : 'asc'
      },
    },
  },
  mounted() {
    this.getUsersList()
  },
  methods: {
    getUsersList(page = 1) {
      this.loading = true
      const params = {
        page: page,
        status: this.optionsSelected,
        produto: this.productSelected,
        painel_vendas: this.salesPanelStatusSelected,
        ordem_data: this.selectedDateOrder,
        busca: this.searchText,
      }
      getUsers(params)
        .then((res) => {
          this.loading = false
          const { data } = res.result
          this.users = data
        })
        .catch(() => {
          this.loading = false
          this.$store.state.modal.isOpenModalErrorRequest = true
        })
    },
    handleClickEdit() {},
    handleDelete(item) {
      const confirm = window.confirm('Deseja realmente excluir este estudo?')
      if (!confirm) return

      this.loading = true
      deleteUser(item.funcionario_id)
        .then(() => {
          this.loading = false
          this.getUsersList()
        })
        .catch(() => {
          this.loading = false
          this.$store.state.modal.isOpenModalErrorRequest = true
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.page-title-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-input-search {
  display: flex;
  align-items: center;
  background: #ffffff;
  height: 2.34rem;
  border: 1px solid #bfbfbf9f;
  border-radius: 4px;

  .input-search {
    border: none;
    background: transparent;
  }

  i {
    color: #000;
  }

  button {
    background: transparent;
    border: 0;
    border-radius: 0;
    height: 100%;
    width: 3rem;

    font-size: 14px;
  }
}

.column-customers {
  display: flex;
  align-items: center;

  span {
    margin-left: 1rem;
  }
}

.progress-bar {
  width: 85%;
  height: 0.45rem;
  background: #e7e7eb;
}

/*  */

@media only screen and (max-width: 1187px) {
  .row-inputs {
    flex-direction: column;

    select {
      margin-bottom: 1rem;
    }
  }
}

.row-inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;

  select {
    display: flex;
    flex-direction: column;
  }

  span {
    font-size: 80%;
  }
}

.btn-new-client {
  display: flex;
  align-items: center;
  width: 7rem;
  background: #556ee6;
  border-color: #556ee6;
  height: 2.2rem;
  font-size: 12px;
}

.btn-new-client-icon {
  display: flex;
  align-items: center;
  height: 2.2rem;
  background: #556ee6;
  border-color: #556ee6;
}

.row-title-description-button {
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
}

.footer-table {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.box-description-quantity-table {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0rem;
  top: 13px;
}

.columnActivity {
  display: flex;
  flex-direction: column;
  width: 12rem;
}

.columnStatus {
  width: 7rem;
}
</style>
