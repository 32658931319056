import api from '@/utils/api'

import {
  CLIENTE,
  DASHBOARD,
  EXPORTAR,
  MOTOR_INTERNO,
} from '../../utils/endPoints'

// GET
export const getDashboardData = async (filters) => {
  const params = {
    ...filters,
  }

  const response = await api.get(`/${MOTOR_INTERNO}/${DASHBOARD}`, {
    params,
  })
  return response
}

export const exportDashboard = async (filters) => {
  const params = {
    ...filters,
  }

  const data = await api.get(`/${MOTOR_INTERNO}/${DASHBOARD}/${EXPORTAR}`, {
    params,
    responseType: 'blob',
  })

  const file = new File([data.data], `export_dashboard.xlsx`, {
    type: data.headers['content-type'],
  })
  return file
}

export const getStudiesAdmin = async (filters) => {
  const params = {
    ...filters,
  }

  const response = await api.get(
    `/${MOTOR_INTERNO}/${CLIENTE}/lista-atividades-admin`,
    {
      params,
    }
  )
  return response
}

export const exportStudies = async (filters) => {
  const params = {
    ...filters,
  }

  const data = await api.get(
    `/${MOTOR_INTERNO}/${CLIENTE}/lista-atividades-admin/${EXPORTAR}`,
    {
      params,
      responseType: 'blob',
    }
  )

  const file = new File([data.data], `export_dashboard.xlsx`, {
    type: data.headers['content-type'],
  })
  return file
}
