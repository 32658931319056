import Profile from './Profile.vue'
import { verifyRouteRolesUser } from '@/router/middlewares'
import * as loggedAsTypes from '@/constants/loggedAsTypes'

export default [
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    beforeEnter: (to, from, next) => {
      return verifyRouteRolesUser(to, from, next, [loggedAsTypes.BROKER])
    },
  },
]
