import Vue from 'vue'
import Vuex from 'vuex'
import auth from '../features/auth/auth.store'
import activityPanel from '../features/activityPanel/activityPanel.store'
import modal from './modal.store'
import diagnosis from './diagnosis.store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    diagnosis,
    activityPanel,
    modal,
  },
})
