<template>
  <div data-testid="title-and-description-screen">
    <h4 class="font-weight-bold">
      {{ title }}
      <span v-if="diagnosis" data-testid="customer-name">
        {{ customerName }}
      </span>
    </h4>
    <p class="font-weight-bold" v-if="!!description">
      {{ description }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    diagnosis: Object,
    title: String,
    customerName: String,
    description: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
h4 {
  margin: 0;
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
}
p {
  color: #74788d;
  font-size: 13px;
  margin-top: 0.5rem;

  @media screen and (max-width: 768px) {
    font-size: 10px;
  }
}
span {
  color: var(--primary);
}
</style>
