import api from '@/utils/api'

import { MOTOR_INTERNO, EMPRESA, SEGURADORAS } from '../../utils/endPoints'

// GET
export const getInsuranceCompany = async () => {
  const data = await api.get(`/${MOTOR_INTERNO}/${EMPRESA}/${SEGURADORAS}`)
  return data
}

export const getAllCompanies = async () => {
  const data = await api.get('/empresa')
  return data
}
