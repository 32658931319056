<template>
  <b-form-group
    :label="label"
    label-cols="0"
    label-size="lg"
    label-class="font-weight-bold"
    :class="`col-12 col-md-${cols} col-sm-12`"
    :label-for="labelFor"
  >
    <slot />
  </b-form-group>
</template>

<script>
export default {
  name: 'AdminFormGroup',
  props: {
    label: String,
    labelFor: String,
    cols: String,
  },
}
</script>
