<template>
  <div class="d-flex align-items-center justify-content-between">
    <div
      :class="
        !active ? 'box-column-pencil-disabled' : 'box-column-pencil-enabled'
      "
    >
      <i class="fas fa-pencil-alt" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    active: Boolean,
  },
}
</script>

<style lang="scss" scoped>
.box-column-pencil-disabled {
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 2rem;
  height: 2rem;
  background: var(--grey-disabled);
  border-radius: 50%;
  color: var(--white);
  cursor: not-allowed;
}

.box-column-pencil-enabled {
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 2rem;
  height: 2rem;
  background: var(--green);
  border-radius: 50%;
  color: var(--white);

  cursor: pointer;
}
</style>
