<template>
  <b-form-group
    :label="label"
    label-cols="0"
    :label-cols-lg="labelColsLg"
    label-size="lg"
    label-class="font-weight-bold"
    class="col-12"
    :label-for="labelFor"
    :style="styleProps"
  >
    <slot />
  </b-form-group>
</template>

<script>
export default {
  name: 'FormGroup',
  props: {
    label: String,
    labelFor: String,
    labelColsLg: {
      default: 3,
    },
    styleProps: String,
  },
}
</script>
