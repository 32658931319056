<template>
  <div
    @click="handleClick()"
    class="d-flex align-items-center justify-content-between"
    data-testid="trash-button"
  >
    <div
      :class="
        !active ? 'box-column-trash-disabled' : 'box-column-trash-enabled'
      "
    >
      <i class="fas fa-trash-alt" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    active: Boolean,
    click: Function,
  },
  methods: {
    handleClick() {
      if (this.active) {
        this.click()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.box-column-trash-enabled {
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 2rem;
  height: 2rem;
  background: var(--red);
  border-radius: 50%;
  color: var(--white);

  cursor: pointer;
}

.box-column-trash-disabled {
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 2rem;
  height: 2rem;
  background: var(--grey-disabled);
  border-radius: 50%;
  color: var(--white);

  cursor: not-allowed;
}
</style>
