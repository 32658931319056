<template>
  <main>
    <b-col class="col-12">
      <div class="page-title-box">
        <div class="d-flex align-items-center">
          <i class="fas fa-angle-left" />
          <router-link to="/customers/add">
            <span style="margin-left: 5px">Voltar</span>
          </router-link>
        </div>
      </div>
    </b-col>

    <b-row>
      <b-col class="col-12">
        <b-card>
          <div
            class="d-flex align-center"
            style="justify-content: space-between"
          >
            <TitleAndDescriptionScreen
              title="Lista de clientes "
              description="Confira todos os clientes."
            />

            <b-button
              style="height: 2rem"
              class="mr-4"
              variant="primary"
              @click="handleExportCustomers()"
            >
              Exportar
            </b-button>
          </div>

          <b-row class="overflow-auto">
            <table class="table">
              <tr>
                <td>
                  <b-form-group
                    label="Nome"
                    label-cols="0"
                    label-size="lg"
                    label-class="font-weight-bold"
                  >
                    <b-form-input
                      placeholder="Nome"
                      type="text"
                      id="dashboard-search-filter-input"
                      v-model="nome"
                    />
                  </b-form-group>
                </td>
                <td>
                  <b-form-group
                    label="Email"
                    label-cols="0"
                    label-size="lg"
                    label-class="font-weight-bold"
                  >
                    <b-form-input
                      placeholder="Email"
                      type="text"
                      id="dashboard-search-filter-input"
                      v-model="email"
                    />
                  </b-form-group>
                </td>
                <td>
                  <b-form-group
                    label="Data de criação"
                    label-size="lg"
                    label-class="font-weight-bold"
                  >
                    <b-form-input
                      type="date"
                      id="dashboard-start-date-filter-input"
                      v-model="data_criacao"
                    />
                  </b-form-group>
                </td>
                <td>
                  <b-form-group
                    label="Consultor"
                    label-cols="0"
                    label-size="lg"
                    label-class="font-weight-bold"
                  >
                    <b-form-select
                      placeholder="Consultor"
                      id="dashboard-search-filter-input"
                      v-model="consultor"
                      :options="funcionariosOptions"
                    />
                  </b-form-group>
                </td>
              </tr>
              <tr v-show="showTable">
                <th>Nome</th>
                <th>Email</th>
                <th>Data de criação</th>
                <th>Consultor</th>
              </tr>
              <tr
                v-for="user in users"
                :key="user.cliente_id"
                v-show="showTable"
              >
                <td>{{ user.pessoa_nome }}</td>
                <td>{{ user.pessoa_email }}</td>
                <td>{{ user.data_criacao | formatDateWithSlash }}</td>
                <td>{{ user.usuario_criador.pessoa.pessoa_nome }}</td>
              </tr>
            </table>

            <b-row
              style="display: flex; justify-content: center; width: 100%"
              v-show="!showTable"
            >
              <span>Nenhum cliente encontrado.</span>
            </b-row>
            <b-row
              style="display: flex; justify-content: center; width: 100%"
              v-show="showTable"
            >
              <li class="pagination-buttons">
                <i
                  :class="
                    currentPage === 1
                      ? 'fas fa-chevron-left style-arrow-left disabled'
                      : 'fas fa-chevron-left style-arrow-left'
                  "
                  @click="currentPage !== 1 ? changePage('prev') : null"
                />
                <label>{{ currentPage }}</label>
                <i
                  :class="
                    currentPage === pagination.lastPage
                      ? 'fas fa-chevron-right style-arrow-right disabled'
                      : 'fas fa-chevron-right style-arrow-right'
                  "
                  @click="
                    currentPage !== pagination.lastPage
                      ? changePage('next')
                      : null
                  "
                />
              </li>
            </b-row>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </main>
</template>

<script>
import TitleAndDescriptionScreen from '@/components/common/TitleAndDescriptionScreen'
import {
  requestGetCustomersAdmin,
  exportCustomers,
} from '@/services/requests/customer'
import { getUsers } from '../../services/requests/users'
import createToastMixin from '@/mixins/create-toast-mixin'

export default {
  name: 'UsersPage',
  components: {
    TitleAndDescriptionScreen,
  },
  mixins: [createToastMixin],
  data() {
    return {
      users: [],
      pagination: {
        total: 0,
        lastPage: 1,
      },
      currentPage: 1,
      nome: '',
      email: '',
      data_criacao: '',
      consultor: null,
      funcionariosOptions: [{ value: null, text: 'Selecione' }],
    }
  },
  computed: {
    showTable() {
      return !!this.users.length
    },
  },
  watch: {
    currentPage() {
      this.getClientsList()
    },
    nome() {
      this.resetPage()
      this.getClientsList()
    },
    email() {
      this.resetPage()
      this.getClientsList()
    },
    data_criacao() {
      this.resetPage()
      this.getClientsList()
    },
    consultor() {
      this.resetPage()
      this.getClientsList()
    },
  },
  mounted() {
    this.getClientsList()
    this.getFuncionariosList()
  },
  methods: {
    changePage(params) {
      if (params === 'next') {
        this.currentPage++
      } else {
        this.currentPage--
      }
    },
    async getClientsList() {
      const data = {
        pessoa_nome: this.nome,
        pessoa_email: this.email,
        data_criacao: this.data_criacao,
        usuario_criador_id: this.consultor,
        includes: 'consultor',
      }

      try {
        const clients = await requestGetCustomersAdmin(this.currentPage, data)

        this.users = clients.data.result.data.data

        this.pagination = {
          total: clients.data.result.data.total,
          lastPage: clients.data.result.data.last_page,
        }
      } catch (error) {
        console.error('Erro ao buscar usuários')
        console.error(error)
      }
    },
    async getFuncionariosList() {
      await getUsers()
        .then((res) => {
          res.result.data.map((item) => {
            this.funcionariosOptions.push({
              value: item.usuario_id,
              text: item.pessoa_nome,
            })
          })
        })
        .catch(() => {
          this.createToast('Erro ao buscar funcionários.', 'Erro!', 'error')
        })
    },
    resetPage() {
      if (this.currentPage !== 1) {
        this.currentPage = 1
      }
    },
    handleExportCustomers() {
      const filters = {
        pessoa_nome: this.nome,
        pessoa_email: this.email,
        data_criacao: this.data_criacao,
        usuario_criador_id: this.consultor,
        includes: 'consultor',
      }
      exportCustomers(filters)
        .then((data) => {
          const href = URL.createObjectURL(data)

          const link = document.createElement('a')
          link.href = href
          document.body.appendChild(link)
          link.click()

          document.body.removeChild(link)
          URL.revokeObjectURL(href)
        })
        .catch(() => {
          this.createToast(
            'Erro ao exportar dados dos clientes.',
            'Erro!',
            'error'
          )
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.page-title-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

table {
  th {
    background-color: #f8f9fa;
  }
}

.footer-table {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.box-description-quantity-table {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0rem;
  top: 13px;
}

.pagination-buttons {
  display: flex;
  align-items: baseline;
  justify-content: center;
  border: 0 !important;
  height: 2rem !important;

  label {
    font-size: 1rem;
    margin-left: 5rem;
    margin-right: 5rem;
  }
}

.style-arrow-right,
.style-arrow-left {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  background: rgb(204, 204, 204);
  opacity: 0.95;
  border-radius: 50%;

  cursor: pointer;
}

.disabled {
  opacity: 0.3;
}
</style>
