import { checkLoggedAs } from '@/features/auth/auth.helpers'
import { SPECIALIST, ADMIN_CUBIX } from '@/constants/loggedAsTypes'

export const verifyRouteRolesUser = (to, from, next, roles) => {
  if (checkLoggedAs(roles)) {
    return next()
  }

  if (checkLoggedAs([ADMIN_CUBIX])) {
    return next({ name: 'Dashboard' })
  }

  if (checkLoggedAs([SPECIALIST])) {
    return next({ name: 'ActivityPanel' })
  }

  return next({ name: 'ToDoList' })
}
