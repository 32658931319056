import api from '@/utils/api'
import { formatData } from '@/helpers'

import {
  COMENTARIO,
  CONTINUAR_DIAGNOSTICO,
  DIAGNOSTICO,
  DIAGNOSTICO_APOLICE,
  DIAGNOSTICO_DOCUMENTO,
  DOWNLOAD,
  DIAGNOSTICO_COMENTARIO,
  DIAGNOSTICO_PRODUTO,
  DIAGNOSTICO_PRODUTO_ITEM,
  DIAGNOSTICO_PRODUTO_ITEM_OPCAO,
  ESTAGIO,
  ESTAGIO_ESPECIALISTA,
  SEGURO_ITEM,
  MOTOR_INTERNO,
  OPCOES,
  PRODUTO_ITEM_TABELA_ITEM,
  PAINEL_ATIVIDADES,
  PAINEL_VENDAS,
  PREVIDENCIA_FUNDO,
  UPLOAD,
  CLIENTE,
  POR_PESSOA_ID,
  PRODUTOS_SELECIONADOS,
  APRESENTACAO_ESTUDO,
  UPDATE_STATUS,
} from '../../utils/endPoints'

export const getDiagnosisTaskList = async (params) => {
  const data = await api.get(`/${MOTOR_INTERNO}/${CLIENTE}/lista-atividades`, {
    params,
  })

  return data
}

export const getDiagnosisSalesPanel = async (params) => {
  const data = await api.get(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO}/${PAINEL_VENDAS}`,
    { params }
  )
  return data
}

export const continueDiagnosis = async () => {
  const data = await api.get(`/${MOTOR_INTERNO}/${CONTINUAR_DIAGNOSTICO}`)
  return data.data
}

export const requestDiagnosis = async (diagnosisId) => {
  const data = await api.get(`/${MOTOR_INTERNO}/${DIAGNOSTICO}/${diagnosisId}`)
  return data
}

export const requestDiagnosisSpecialist = async (diagnosisId) => {
  const data = await api.get(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO}/${diagnosisId}/especialista`
  )
  return data
}

export const requestDiagnosisActivityPanel = async (params) => {
  const data = await api.get(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO}/${PAINEL_ATIVIDADES}`,
    { params }
  )
  return data
}

export const requestDocumentDiagnosis = async (diagnostico_documento_id) => {
  const data = await api.get(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO_DOCUMENTO}/${DOWNLOAD}/${diagnostico_documento_id}`
  )
  return data
}

export const requestPolicyDiagnosis = async (diagnostico_apolice_id) => {
  const data = await api.get(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO_APOLICE}/${DOWNLOAD}/${diagnostico_apolice_id}`
  )
  return data
}

export const getPerson = (id) => {
  return api.get(`/${MOTOR_INTERNO}/pessoa/${id}`)
}

// PUT
export const updateComment = async (data) => {
  const response = await api.put(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO_COMENTARIO}/${data.diagnostico_comentario_id}`,
    data
  )
  return response
}

export const updateCommentWithFile = async (
  urlResource,
  id,
  urlAppend,
  data
) => {
  const response = await api.put(
    `/${MOTOR_INTERNO}/${urlResource}/${id}${urlAppend}`,
    data
  )
  return response
}

export const updatePhaseSpecialist = async (diagnosisId, body) => {
  const data = await api.put(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO}/${diagnosisId}/${ESTAGIO_ESPECIALISTA}`,
    body
  )
  return data
}

export const updatePhase = async (diagnosisId, body) => {
  const data = await api.put(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO}/${diagnosisId}/${ESTAGIO}`,
    body
  )
  return data
}

export const updateDocumentDiagnosis = async (
  diagnostico_documento_id,
  body
) => {
  const data = await api.put(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO_DOCUMENTO}/${diagnostico_documento_id}`,
    body
  )
  return data
}

export const updateDocumentPolicy = async (diagnostico_apolice_id, body) => {
  const data = await api.put(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO_APOLICE}/${diagnostico_apolice_id}`,
    body
  )
  return data
}

export const updateDiagnosis = async (diagnostico_id, body) => {
  const data = await api.put(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO}/${diagnostico_id}`,
    body
  )
  return data
}

export const updateDiagnosisProduct = async (diagnosisProductId, body) => {
  const data = await api.put(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO_PRODUTO}/${diagnosisProductId}`,
    body
  )
  return data
}

export const updateDiagnosisProductSpecialist = async (diagnosisProductId) => {
  const data = await api.put(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO_PRODUTO}/${diagnosisProductId}/especialista`
  )
  return data
}

export const updateDiagnosisProductItem = async (
  diagnostico_product_item_id,
  data
) => {
  const response = await api.put(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO_PRODUTO_ITEM}/${diagnostico_product_item_id}`,
    data
  )
  return response
}

export const updateStatusItem = async (id, data) => {
  const response = await api.put(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO_PRODUTO_ITEM_OPCAO}/${id}/${UPDATE_STATUS}`,
    data
  )
  return response
}

export const updateDiagnosisProductItemOpcoes = async (
  diagnostico_product_item_id,
  data
) => {
  const response = await api.put(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO_PRODUTO_ITEM}/${diagnostico_product_item_id}/${OPCOES}`,
    data
  )
  return response
}

export const updateDiagnosisProductItemComentario = async (
  diagnostico_product_item_id,
  data
) => {
  const response = await api.put(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO_PRODUTO_ITEM}/${diagnostico_product_item_id}/${COMENTARIO}`,
    data
  )
  return response
}

export const updateProductItemTableItem = async (
  product_item_table_item,
  tableItem
) => {
  const response = await api.put(
    `/${MOTOR_INTERNO}/${PRODUTO_ITEM_TABELA_ITEM}/${product_item_table_item}`,
    tableItem
  )
  return response
}

export const updateCommentDiagnosis = async (diagnostico_id, body) => {
  const data = await api.put(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO_COMENTARIO}/${diagnostico_id}`,
    body
  )
  return data
}

export const updateProductItemOption = async (optionId, data) => {
  const response = await api.put(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO_PRODUTO_ITEM_OPCAO}/${optionId}`,
    data
  )
  return response
}

export const updateSelectedProducts = async (diagnosisId, products) => {
  const response = await api.put(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO}/${diagnosisId}/${PRODUTOS_SELECIONADOS}`,
    {
      diagnostico_produtos: products,
    }
  )
  return response
}

// PATCH

export const updateStudyTitle = async (studyPresentationID, data) => {
  const response = await api.patch(
    `/${MOTOR_INTERNO}/${APRESENTACAO_ESTUDO}/${studyPresentationID}`,
    data
  )
  return response
}

// POST
export const addComment = async (data) => {
  const response = await api.post(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO_COMENTARIO}`,
    data
  )
  return response
}

export const addCommentSpecialist = async (data) => {
  const response = await api.post(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO_COMENTARIO}/especialista`,
    data
  )
  return response
}

export const uploadPolicyFile = async (policy, policyType, file) => {
  const data = await api.post(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO_APOLICE}/${policy.diagnostico_apolice_id}/${policyType}`,
    formatData('file', file),
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
  return data
}

export const deletePolicyFile = async (policy, policyType) => {
  const data = await api.delete(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO_APOLICE}/${policy.diagnostico_apolice_id}/${policyType}`
  )
  return data
}

export const uploadDocumentFile = async (document_id, file) => {
  const data = await api.post(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO_DOCUMENTO}/${document_id}`,
    formatData('file', file),
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
  return data
}

export const createComment = async (data) => {
  const response = await api.post(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO_COMENTARIO}`,
    data
  )
  return response
}

export const createDiagnosis = async (data) => {
  const response = await api.post(`/${MOTOR_INTERNO}/${DIAGNOSTICO}`, data)
  return response
}

export const uploadDiagnosisPolicy = async (body) => {
  const data = await api.post(`/${MOTOR_INTERNO}/${DIAGNOSTICO_APOLICE}`, body)
  return data
}

export const uploadSecurityItem = async (id, file, fileName) => {
  const data = await api.post(
    `/${MOTOR_INTERNO}/${UPLOAD}/${SEGURO_ITEM}/${id}`,
    formatData('file', file, fileName),
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
  return data
}

export const uploadPencionFundItem = async (id, file, fileName) => {
  const data = await api.post(
    `/${MOTOR_INTERNO}/${UPLOAD}/${PREVIDENCIA_FUNDO}/${id}`,
    formatData('file', file, fileName),
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
  return data
}

export const uploadProductItemOption = (id, file) => {
  return api.post(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO_PRODUTO_ITEM_OPCAO}/file/${id}`,
    formatData('file', file),
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
}

export const upload2ProductItemOption = async (id, file) => {
  const data = await api.post(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO_PRODUTO_ITEM_OPCAO}/file/${id}/2`,
    formatData('file', file),
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
  return data
}

export const createProductItemOption = async (data) => {
  const response = await api.post(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO_PRODUTO_ITEM_OPCAO}`,
    data
  )
  return response
}

export const updateStudyPresentation = async (id, data) => {
  const response = await api.post(
    `/${MOTOR_INTERNO}/${APRESENTACAO_ESTUDO}/${id}`,
    data
  )
  return response
}

export const duplicateStudy = async (id) => {
  const response = await api.post(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO}/${id}/duplicar`
  )
  return response
}

// DELETE
export const deleteDiagnosisPolicy = async (diagnostico_apolice_id) => {
  const data = await api.delete(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO_APOLICE}/${diagnostico_apolice_id}`
  )
  return data
}

export const deleteCommentDiagnosis = async (commentId) => {
  const data = await api.delete(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO_COMENTARIO}/${commentId}`
  )
  return data
}

export const deleteDiagnosis = async (diagnostico_id) => {
  const data = await api.delete(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO}/${diagnostico_id}`
  )
  return data
}

export const deleteOptionFile = async (diagnostico_produto_item_opcao_id) => {
  const data = await api.delete(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO_PRODUTO_ITEM_OPCAO}/file/${diagnostico_produto_item_opcao_id}`
  )
  return data
}

export const deleteOptionFile2 = async (diagnostico_produto_item_opcao_id) => {
  const data = await api.delete(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO_PRODUTO_ITEM_OPCAO}/file/${diagnostico_produto_item_opcao_id}/2`
  )
  return data
}

export const deleteDocumentFile = async (diagnostico_documento_id) => {
  const data = await api.delete(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO_DOCUMENTO}/${diagnostico_documento_id}/file`
  )
  return data
}

export const deleteProductItemOption = async (optionId) => {
  const data = await api.delete(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO_PRODUTO_ITEM_OPCAO}/${optionId}`
  )
  return data
}

export const getClientActivities = async (personId) => {
  const data = await api.get(
    `/${MOTOR_INTERNO}/${DIAGNOSTICO_PRODUTO_ITEM}/${POR_PESSOA_ID}/${personId}`
  )

  return data
}
