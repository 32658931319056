export default {
  methods: {
    createToast(message, title, variant = 'secondary') {
      this.$bvToast.toast(message, {
        title,
        toaster: 'b-toaster-top-right',
        variant: variant,
        solid: true,
        autoHideDelay: 6000,
      })
    },
  },
}
