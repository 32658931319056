import api from '@/utils/api'
import {
  FUNCIONARIO,
  UPLOAD_LOGO,
  UPLOAD_TERMS,
  DOWNLOAD_TERMS,
  LOGADO,
} from '../../utils/endPoints'
import { formatData } from '@/helpers'

export const getUsers = async () => {
  const users = await api.get(`/funcionario`)
  return users.data
}

export const deleteUser = async (user_id) => {
  const data = await api.delete(`/funcionario/${user_id}`)
  return data
}

export const registerUser = async (user) => {
  const response = await api.post(`/funcionario`, user)
  return response
}

export const updateUser = async (user, id) => {
  const response = await api.put(`/funcionario/${id}`, user)
  return response
}

export const getFuncionarioOpcoes = async (name) => {
  const params = { pessoa_nome: name }
  const data = await api.get('funcionario/opcoes', params)
  return data
}

export const uploadAvatar = async (file, id) => {
  const data = await api.post(
    `${FUNCIONARIO}/${UPLOAD_LOGO}/${id}`,
    formatData('img', file),
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )

  return data
}

export const updateCurrentUser = async (data) => {
  const result = await api.post(`${FUNCIONARIO}/${LOGADO}`, {
    nome: data.name,
    email: data.email,
    senha: data.password,
    telefone: data.phone,
    estado: data.state,
    cidade: data.city,
    usuario_username: data.username,
  })

  return result
}

export const uploadTerms = async (file, id) => {
  const data = await api.post(
    `${FUNCIONARIO}/${UPLOAD_TERMS}/${id}`,
    formatData('file', file),
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'blob',
    }
  )

  return data
}

export const downloadTerms = async (id) => {
  const data = await api.get(`${FUNCIONARIO}/${DOWNLOAD_TERMS}/${id}`, {
    responseType: 'blob',
  })

  return data
}
