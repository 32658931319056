export const cloneObject = (value) => {
  return JSON.parse(JSON.stringify(value))
}

export const formatData = (...rest) => {
  let formData = new FormData()
  formData.append(...rest)

  return formData
}

export const filterSuggestedSolutions = (
  diagnosis,
  productName,
  productGroups
) => {
  const solutions = {
    holder: {
      options: [],
    },
    spouse: {
      options: [],
    },
  }

  diagnosis.diagnostico_produtos
    .find((product) => product.nome_produto === productName)
    .diagnostico_produto_itens.filter((item) =>
      productGroups.includes(item.grupo)
    )
    .forEach((item) =>
      item.diagnostico_produto_item_opcoes.forEach((option) =>
        !item.conjuge
          ? solutions.holder.options.push(option)
          : solutions.spouse.options.push(option)
      )
    )

  solutions.holder.name = diagnosis.pessoa.pessoa_nome
  solutions.spouse.name = diagnosis.pessoa.pessoa_conjuge.nome_completo

  return solutions
}

export const downloadFile = (filePath, fileName) => {
  const link = document.createElement('a')

  link.href = filePath
  link.setAttribute('download', fileName || '')

  document.body.appendChild(link)

  link.click()
  link.remove()
}

export const validateLettersAndSpaces = (e) => {
  let char = String.fromCharCode(e.keyCode)
  if (/^[A-Za-z\s]+$/.test(char)) return
  e.preventDefault()
}

export const customerHasSpouse = (customer) => {
  return (
    ['C', 'UE'].includes(customer.pessoa.pessoa_estado_civil) &&
    customer.pessoa.pessoa_conjuge.analise_perfil
  )
}
