let navbar = {
  navbar: {
    greeting: 'Bem-Vindo(a)',
    perfil: 'Perfil',
    configurations: 'Configurações',
    logout: 'Sair',
  },
}

export default navbar
