import api from '@/utils/api'

import {
  MOTOR_INTERNO,
  CLIENTE,
  POR_DIAGNOSTICO,
  POR_DIAGNOSTICO_ESPECIALISTA,
  EXPORTAR,
} from '../../utils/endPoints'

// GET
export const requestGetCustomers = async (page, params) => {
  const data = await api.get(`/${MOTOR_INTERNO}/${CLIENTE}?page=${page}`, {
    params,
  })
  return data
}

export const requestGetCustomersAdmin = async (page, params) => {
  const data = await api.get(
    `/${MOTOR_INTERNO}/${CLIENTE}/admin?page=${page}`,
    {
      params,
    }
  )
  return data
}

export const requestGetCustomerId = async (cliente_id) => {
  const data = await api.get(`/${MOTOR_INTERNO}/${CLIENTE}/${cliente_id}`)
  return data
}

export const requestGetCustomerByDiagnosis = async (params) => {
  const data = await api.get(
    `/${MOTOR_INTERNO}/${CLIENTE}/${POR_DIAGNOSTICO}`,
    { params }
  )
  return data
}

export const requestGetCustomerByDiagnosisSpecialist = async (params) => {
  const data = await api.get(
    `/${MOTOR_INTERNO}/${CLIENTE}/${POR_DIAGNOSTICO_ESPECIALISTA}`,
    { params }
  )
  return data
}

export const exportCustomers = async (filters) => {
  const params = {
    ...filters,
  }

  const data = await api.get(`/${MOTOR_INTERNO}/${CLIENTE}/admin/${EXPORTAR}`, {
    params,
    responseType: 'blob',
  })

  const file = new File([data.data], `export_dashboard.xlsx`, {
    type: data.headers['content-type'],
  })
  return file
}

// POST
export const uploadDocumentIR = async (cliente_id, resource, body) => {
  const data = await api.post(
    `/${MOTOR_INTERNO}/${CLIENTE}/${cliente_id}/${resource}`,
    body,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
  return data
}

export const uploadCustomer = async (data) => {
  const response = await api.post(`/${MOTOR_INTERNO}/${CLIENTE}`, data)
  return response
}

export const duplicateCustomer = async (clienteId) => {
  const response = await api.post(
    `/${MOTOR_INTERNO}/${CLIENTE}/${clienteId}/duplicar`
  )
  return response
}

// PUT
export const updateCustomer = async (cliente_id, data) => {
  const response = await api.put(
    `/${MOTOR_INTERNO}/${CLIENTE}/${cliente_id}`,
    data
  )
  return response
}

// DELETE
export const deleteCustomer = async (cliente_id) => {
  const data = await api.delete(`/${MOTOR_INTERNO}/${CLIENTE}/${cliente_id}`)
  return data
}
