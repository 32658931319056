import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from '@/features/auth/auth.routes'
import customers from '@/features/customers/customers.routes'
import diagnosis from '@/src-consultant/routes/diagnosis.routes'
import profile from '@/features/profile/profile.routes'
import salesPanel from '@/features/salesPanel/salesPanel.routes'
import activityPanel from '@/features/activityPanel/activityPanel.routes'
import toDoList from '@/features/toDoList/toDoList.routes'
import admin from '@/src-admin/routes/admin.routes'

import specialistSteps from '@/src-specialist/routes/specialist.routes.js'

import { authGuard } from '@/features/auth/auth.middleware'
import { verifyRouteRolesUser } from './middlewares'

Vue.use(VueRouter)

const routes = [
  ...auth,
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404Page.vue'),
  },
  {
    path: '*',
    redirect: '404',
  },
  {
    path: '/',
    component: () => import('@/views/MainPage.vue'),
    children: [
      {
        path: '',
        name: 'home',
        beforeEnter: (to, from, next) =>
          verifyRouteRolesUser(to, from, next, []),
      },
      {
        path: '/terms-of-use',
        name: 'TermsOfUse',
        component: () => import('@/views/TermsOfUse.vue'),
      },
      ...customers,
      ...diagnosis,
      ...profile,
      ...salesPanel,
      ...activityPanel,
      ...toDoList,
      ...specialistSteps,
      ...admin,
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(authGuard)

export default router
