import axios from 'axios'
import Config from '@/config/config'

const CONFIG = new Config()

export const api = axios.create({
  baseURL: CONFIG.API_URL,
})

export default api

export function interceptors(cb) {
  const onSuccess = (res) => res

  const onError = (err) => {
    cb(err)
    return Promise.reject(err)
  }

  api.interceptors.response.use(onSuccess, onError)
}
