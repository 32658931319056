import { getAuth, setAuth } from './auth.helpers'
import Config from '@/config/config'
import axios from 'axios'
import * as loggedAsTypes from '@/constants/loggedAsTypes'
import { ESPECIALISTA, ADMIN_CUBIX } from '@/constants/userRoles'
import api from '@/utils/api'

const CONFIG = new Config()

const state = getAuth() ?? {
  user: null,
  token: null,
  authentication: null,
  acl: null,
  loggedAs: loggedAsTypes.BROKER,
}

export const getters = {
  isAuthenticated: (state) => !!state.token,
  loggedUserRole: (state) => {
    if (!state.user) return null

    return state.user.pessoa.funcionario.funcionario_cargo_id
  },
}

export const mutations = {
  SET_CURRENT_USER(state, { user, token, acl, loggedAs }) {
    state.user = user
    state.token = token
    state.acl = acl
    state.loggedAs = loggedAs

    setAuth({
      user,
      token,
      acl,
      loggedAs,
    })
  },
  LOGOUT(state) {
    state.user = null
    state.token = null

    setAuth({
      user: null,
      token: null,
      acl: null,
      loggedAs: loggedAsTypes.BROKER,
    })
  },
}

export const actions = {
  CREATE_USER(_, _payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(CONFIG.API_URL + '/auth/signup', _payload)
        .then((_response) => {
          resolve(_response)
        })
        .catch((_error) => {
          reject(_error.response.data.code)
        })
    })
  },
  LOGIN({ commit }, { login, senha }) {
    return new Promise((resolve, reject) => {
      let payload = { usuario_username: login, password: senha }
      axios
        .post(`${CONFIG.API_URL}authenticate`, payload)
        .then((_response) => {
          let user = _response.data.usuario

          let loggedAs = loggedAsTypes.BROKER

          if (user.pessoa.funcionario.funcionario_cargo_id === ESPECIALISTA) {
            loggedAs = loggedAsTypes.SPECIALIST
          }

          if (user.pessoa.funcionario.funcionario_cargo_id === ADMIN_CUBIX) {
            loggedAs = loggedAsTypes.ADMIN_CUBIX
          }

          user.avatar =
            _response.data.avatar ??
            'https://images.pexels.com/users/avatars/68/tim-savage-333.png?auto=compress&fit=crop&h=256&w=256'
          user.nomeCompleto = user.pessoa.pessoa_nome
          user.nome = user.pessoa.pessoa_nome.split(' ')[0]
          delete _response.data.usuario
          let acl = _response.data.permissoes
          let token = _response.data.token

          commit('SET_CURRENT_USER', { user, token, acl, loggedAs })
          resolve({
            user,
            token,
            acl,
            loggedAs,
            isAuthenticated: true,
          })
        })
        .catch((_error) => {
          reject(_error.response?.data.code || 'Usuário ou senha inválidos')
          setTimeout(function () {
            if (document.getElementsByClassName('alert-danger')?.length) {
              document.getElementsByClassName('alert-danger')[0].innerText =
                'Usuário ou Senha inválida'
            }
          }, 100)
        })
    })
  },
  forgotPassword(_, { login }) {
    return new Promise((resolve, reject) => {
      const payload = { usuario_username: login }

      axios
        .post(`${CONFIG.API_URL}recuperacao-senha`, payload)
        .then((_response) => {
          resolve(_response.data.statusMessage)
        })
        .catch((_error) => {
          if (_error.response.status !== 422) {
            return reject(_error.response.data.statusMessage)
          }

          const key = Object.keys(_error.response.data.errors)[0]
          reject(_error.response.data.errors[key][0])
        })
    })
  },
  resetPasswordGet(_, { token }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${CONFIG.API_URL}recuperacao-senha/${token}`)
        .then((_response) => {
          resolve(_response.data.result.data)
        })
        .catch((_error) => {
          reject(_error)
        })
    })
  },
  resetPassword(_, { password, confirmPassword, token }) {
    return new Promise((resolve, reject) => {
      const payload = {
        senha: password,
        confirmacao_senha: confirmPassword,
      }

      axios
        .put(`${CONFIG.API_URL}recuperacao-senha/${token}`, payload)
        .then((_response) => {
          resolve(_response.data)
        })
        .catch((_error) => {
          if (_error.response.status !== 422) {
            return reject(_error.response.data.statusMessage)
          }

          const key = Object.keys(_error.response.data.errors)[0]
          reject(_error.response.data.errors[key][0])
        })
    })
  },
  logOut({ commit }) {
    return new Promise((resolve) => {
      setTimeout(() => {
        commit('LOGOUT')
        resolve()
      }, 1000)
    })
  },
  loadUser({ state, commit }) {
    return api.get(`${CONFIG.API_URL}authenticate/me`).then((_response) => {
      let user = _response.data.result.data
      user.avatar =
        _response.data.avatar ??
        'https://images.pexels.com/users/avatars/68/tim-savage-333.png?auto=compress&fit=crop&h=256&w=256'
      user.nomeCompleto = user.pessoa.pessoa_nome
      user.nome = user.pessoa.pessoa_nome.split(' ')[0]

      let acl = _response.data.result.data.permissoes
      let token = state.token
      const loggedAs = state.loggedAs

      commit('SET_CURRENT_USER', { user, token, acl, loggedAs })
    })
  },
  changeLoggedAs({ state, commit }, { loggedAs }) {
    const { user, token, acl } = state
    commit('SET_CURRENT_USER', { user, token, acl, loggedAs })
  },
}

export default {
  name: 'auth',
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
