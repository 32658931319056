import { verifyRouteRolesUser } from '../../router/middlewares'
import * as loggedAsTypes from '@/constants/loggedAsTypes'
import Users from '../pages/Users'
import DashboardPage from '../pages/DashboardPage'
import Clientes from '../pages/Clientes'
import Estudos from '../pages/Estudos'
import UserForm from '../components/UserForm'

export default [
  {
    path: '/users',
    name: 'Users',
    component: Users,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.ADMIN_CUBIX]),
  },
  {
    path: '/users/editar/:id',
    name: 'UsersEditar',
    component: UserForm,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.ADMIN_CUBIX]),
  },
  {
    path: '/users/cadastro',
    name: 'UsersCadastro',
    component: UserForm,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.ADMIN_CUBIX]),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardPage,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.ADMIN_CUBIX]),
  },
  {
    path: '/customers-admin',
    name: 'ClientesAdmin',
    component: Clientes,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.ADMIN_CUBIX]),
  },
  {
    path: '/studies-admin',
    name: 'EstudosAdmin',
    component: Estudos,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.ADMIN_CUBIX]),
  },
]
