import * as loggedAsTypes from '@/constants/loggedAsTypes'
import { verifyRouteRolesUser } from '@/router/middlewares'
const CustomersPage = () => import('./CustomersPage.vue')
const CustomersAddPage = () => import('./CustomersAddPage.vue')
const CustomersEditPage = () => import('./CustomersEditPage.vue')
const CustomersPageSpecialist = () => import('./CustomersPageSpecialist.vue')

export default [
  {
    path: '/customers',
    name: 'Customers',
    component: CustomersPage,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.BROKER]),
  },
  {
    path: '/customers/add',
    name: 'CustomersAdd',
    component: CustomersAddPage,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.BROKER]),
  },
  {
    path: '/customers/:customerId',
    name: 'CustomersEdit',
    component: CustomersEditPage,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.BROKER]),
  },
  {
    path: '/customers-specialist',
    name: 'CustomersSpecialist',
    component: CustomersPageSpecialist,
    beforeEnter: (to, from, next) =>
      verifyRouteRolesUser(to, from, next, [loggedAsTypes.SPECIALIST]),
  },
]
